import { ServerError } from '@/services/auth.service.types'
import { AdminService } from '@/services/admin.service'
import { ExercisesDictionaries } from '@/types/trainings'
import { TrainerShort } from '@/types/users'
import axios from 'axios'
import {
  ActionContext,
  ActionTree,
  createLogger,
  createStore,
  MutationTree,
} from 'vuex'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

import {
  AuthState,
  initialState as authInitialState,
  AuthMutations,
  authMutations,
  AuthActions,
  authActions,
  AuthGetters,
  authGetters,
  AuthActionTypes,
} from './auth.module'
import { ContentService } from '@/services/content.service'
import { AppNotification } from '@/types'
import { getErrorsList, isServerError } from '@/services/auth.service.helpers'
import { SubscriptionsDictionaries } from '@/types/subscriptions'
import {
  cmsInitialState,
  cmsMutations,
  CmsMutations,
  CmsState,
} from './cms.module'

// TODO
export const handleError = (e: Error): Promise<Error> => {
  const error = axios.isAxiosError(e) ? (e as ServerError) : e
  console.error('error', error)
  return Promise.reject(error)
}

export type State = {
  cms: CmsState
  auth: AuthState
  isAuthorizing: boolean
  exercisesDictionaries?: ExercisesDictionaries
  subscriptionsDictionaries?: SubscriptionsDictionaries
  trainersShorts?: TrainerShort[]
  trainingsDate?: Date
  paymentMethods?: Record<number, string>
  notification?: AppNotification
}

const trainingsDateStr = localStorage.getItem('trainingsDateStr')

const state: State = {
  cms: cmsInitialState,
  auth: authInitialState,
  isAuthorizing: false,
  trainingsDate: trainingsDateStr
    ? new Date(Number(trainingsDateStr))
    : new Date(),
}

export enum MutationTypes {
  AuthMutationTypes,
  CmsMutationTypes,
  SET_IS_AUTHORIZING = 'SET_IS_AUTHORIZING',
  SET_EXERCISES_DICTIONARIES = 'SET_EXERCISES_DICTIONARIES',
  SET_SUBSCRIPTIONS_DICTIONARIES = 'SET_SUBSCRIPTIONS_DICTIONARIES',
  SET_TRAINERS_SHORTS = 'SET_TRAINERS_SHORTS',
  SET_EXERCISES_DATE = 'SET_EXERCISES_DATE',
  SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS',
  SET_NOTIFICATON = 'SET_NOTIFICATON',
}

export type Mutations<S = State> = CmsMutations &
  AuthMutations & {
    [MutationTypes.SET_IS_AUTHORIZING](state: S, isAuthorizing: boolean): void
    [MutationTypes.SET_EXERCISES_DICTIONARIES](
      state: State,
      dictionaries: ExercisesDictionaries
    ): void
    [MutationTypes.SET_SUBSCRIPTIONS_DICTIONARIES](
      state: State,
      dictionaries: SubscriptionsDictionaries
    ): void
    [MutationTypes.SET_TRAINERS_SHORTS](
      state: State,
      trainers: TrainerShort[]
    ): void
    [MutationTypes.SET_EXERCISES_DATE](state: State, date: Date): void
    [MutationTypes.SET_NOTIFICATON](
      state: State,
      notification: AppNotification
    ): void
    [MutationTypes.SET_PAYMENT_METHODS](
      state: State,
      methods: Record<number, string>
    ): void
  }

const mutations: MutationTree<State> & Mutations = {
  ...cmsMutations,
  ...authMutations,
  [MutationTypes.SET_IS_AUTHORIZING](state, isAuthorizing) {
    state.isAuthorizing = isAuthorizing
  },
  [MutationTypes.SET_SUBSCRIPTIONS_DICTIONARIES](state, dictionaries) {
    state.subscriptionsDictionaries = dictionaries
  },
  [MutationTypes.SET_EXERCISES_DICTIONARIES](state, dictionaries) {
    state.exercisesDictionaries = dictionaries
  },
  [MutationTypes.SET_TRAINERS_SHORTS](state, trainers) {
    state.trainersShorts = trainers
  },
  [MutationTypes.SET_EXERCISES_DATE](state, date) {
    state.trainingsDate = date
    localStorage.setItem('trainingsDateStr', date.getTime().toString())
  },
  [MutationTypes.SET_PAYMENT_METHODS](state, methods) {
    state.paymentMethods = methods
  },
  [MutationTypes.SET_NOTIFICATON](state, notification) {
    state.notification = notification
    setTimeout(() => {
      state.notification = undefined
    }, 3000)
  },
}

export type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export enum IndexActionTypes {
  FETCH_EXERCISES_DICTIONARIES = 'FETCH_EXERCISES_DICTIONARIES',
  FETCH_SUBSCRIPTION_DICTIONARIES = 'FETCH_SUBSCRIPTION_DICTIONARIES',
  FETCH_TRAINERS_SHORTS = 'FETCH_TRAINERS_SHORTS',
  FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS',
  HANDLE_ERROR = 'HANDLE_ERROR',
}

export const ActionTypes = {
  ...AuthActionTypes,
  ...IndexActionTypes,
}

export type Actions = AuthActions & {
  [ActionTypes.FETCH_SUBSCRIPTION_DICTIONARIES]({
    commit,
  }: AugmentedActionContext): Promise<SubscriptionsDictionaries | Error>
  [ActionTypes.FETCH_EXERCISES_DICTIONARIES]({
    commit,
  }: AugmentedActionContext): Promise<ExercisesDictionaries | Error>
  [ActionTypes.FETCH_TRAINERS_SHORTS]({
    commit,
  }: AugmentedActionContext): Promise<TrainerShort[] | Error>
  [ActionTypes.FETCH_PAYMENT_METHODS]({
    commit,
  }: AugmentedActionContext): Promise<Record<number, string> | Error>
  [ActionTypes.HANDLE_ERROR](
    { commit }: AugmentedActionContext,
    payload: unknown
  ): void
}

export const actions: ActionTree<State, State> & Actions = {
  ...authActions,
  async [ActionTypes.FETCH_SUBSCRIPTION_DICTIONARIES]({ commit }) {
    try {
      const { data } = await AdminService.getSubscriptionsDictionaries()
      console.log('fetch sub dictionaries', data)
      commit(MutationTypes.SET_SUBSCRIPTIONS_DICTIONARIES, data)
      return Promise.resolve(data)
    } catch (e) {
      return handleError(e)
    }
  },
  async [ActionTypes.FETCH_EXERCISES_DICTIONARIES]({ commit }) {
    try {
      const { data } = await AdminService.getExerciseDictionaries()
      console.log('fetch dictionaries', data)
      commit(MutationTypes.SET_EXERCISES_DICTIONARIES, data)
      return Promise.resolve(data)
    } catch (e) {
      return handleError(e)
    }
  },
  async [ActionTypes.FETCH_TRAINERS_SHORTS]({ commit }) {
    try {
      const { data } = await AdminService.getTrainersShorts({ is_active: true })
      console.log('fetch trainers', data)
      commit(MutationTypes.SET_TRAINERS_SHORTS, data.results)
      return Promise.resolve(data.results)
    } catch (e) {
      return handleError(e)
    }
  },
  async [ActionTypes.FETCH_PAYMENT_METHODS]({ commit }) {
    try {
      const { data } = await ContentService.getPaymentMethods()
      console.log('fetch payment methods', data)
      commit(MutationTypes.SET_PAYMENT_METHODS, data.payment_methods)
      return Promise.resolve(data.payment_methods)
    } catch (e) {
      return handleError(e)
    }
  },
  [ActionTypes.HANDLE_ERROR]({ commit }, error) {
    if (isServerError(error)) {
      commit(MutationTypes.SET_NOTIFICATON, {
        msg: getErrorsList(error).join('\n'),
        type: 'error',
      })
    } else {
      console.log('unknown error', error)
    }
  },
}
export type Getters = AuthGetters
export const getters = authGetters

export type Store = Omit<
  VuexStore<State>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}

export const store = createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createLogger()],
})

export function useStore(): Store {
  return store as Store
}
