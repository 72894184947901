import { User } from '@/types/users'

const getUser = (): User | undefined => {
  const userStr = localStorage.getItem('user')
  if (userStr) {
    return JSON.parse(userStr)
  }
  return undefined
}

const setUser = (user: User | undefined): void => {
  if (!user) {
    localStorage.removeItem('user')
  } else {
    localStorage.setItem('user', JSON.stringify(user))
  }
}

export const StorageService = {
  // getLocalAccessToken() {
  //   const user = getUser()
  //   return user?.access_token
  // },
  // getLocalRefreshToken() {
  //   const user = getUser()
  //   return user?.refresh_token
  // },
  // updateLocalAccessToken(token) {
  //   const user = getUser()
  //   user.access_token = token
  //   setUser(user)
  // },
  getUser,
  setUser,
  removeUser(): void {
    localStorage.removeItem('user')
  },
}
