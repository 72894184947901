import script from "./Authorization.vue?vue&type=script&setup=true&lang=ts"
export * from "./Authorization.vue?vue&type=script&setup=true&lang=ts"

import "./Authorization.vue?vue&type=style&index=0&id=3f59cadb&lang=sass"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import FormLogin from "@/components/FormLogin.vue";
import FormPhoneVerification from "@/components/FormPhoneVerification.vue";
import FormRegister from "@/components/FormRegister.vue";
import FormResetPassword from "@/components/FormResetPassword.vue";
script.components = Object.assign({}, { FormLogin, FormPhoneVerification, FormRegister, FormResetPassword }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { FormLogin, FormPhoneVerification, FormRegister, FormResetPassword }, script.__o.components);}