import { Routes } from '@/router'
import { ActionTypes, Store } from '@/store'
import { stopProgressBar } from '@/use/progressBar'
import { Router } from 'vue-router'
import apiClient from './client'

export default function (store: Store, router: Router): void {
  apiClient.interceptors.response.use(
    (res) => {
      stopProgressBar()
      return res
    },
    (error) => {
      stopProgressBar()
      const { response } = error
      if (response && (response.status === 401 || response.status == 403)) {
        console.log('logout')
        // dummy payload
        store.dispatch(ActionTypes.LOGOUT, undefined)
        router.push({ name: Routes.MAIN })
      }
      return Promise.reject(error)
    }
  )
}
