import { allEndpoints, allDynamicEndpoints } from '@/config/endpoints'
import {
  ContentServiceRequest,
  FAQType,
  Format,
  Page,
  QA,
  Review,
  TrainerBio,
} from '@/types/content'
import { ScheduleDay } from '@/types/schedule'
import { Tariff } from '@/types/subscriptions'
import { startProgressBar } from '@/use/progressBar'
import apiClient from './client'

const endpoints = allEndpoints.content
const dynamicEndpoins = allDynamicEndpoints.content

export const ContentService = {
  getSubscriptions(): Promise<{ data: Tariff[] }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: endpoints.subscriptions,
    })
  },

  getPaymentMethods(): Promise<{
    data: { payment_methods: Record<number, string> }
  }> {
    return apiClient({
      method: 'get',
      url: endpoints.paymentMethods,
    })
  },

  getPage(page_url: string): Promise<{
    data: Page
  }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoins.page(page_url),
    })
  },

  getPages(): Promise<{ data: { id: string; url: string; title: string }[] }> {
    return apiClient({
      method: 'get',
      url: endpoints.pages,
    })
  },

  getReviews(): Promise<{ data: Review[] }> {
    return apiClient({
      method: 'get',
      url: endpoints.reviews,
    })
  },

  getTrainersBios(): Promise<{ data: TrainerBio[] }> {
    return apiClient({
      method: 'get',
      url: endpoints.trainers,
    })
  },

  getFaq(type: FAQType): Promise<{ data: QA[] }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoins.faq(type),
    })
  },

  submitContactForm(
    data: ContentServiceRequest
  ): Promise<{ data: ContentServiceRequest }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.contactForm,
      data,
    })
  },

  getFormats(): Promise<{ data: Format[] }> {
    return apiClient({
      method: 'get',
      url: endpoints.formats,
    })
  },

  getSchedule(): Promise<{ data: ScheduleDay[] }> {
    return apiClient({
      method: 'get',
      url: endpoints.schedule,
    })
  },
}
