import { createRouter, createWebHistory } from 'vue-router'
import { RouteRecordRaw } from 'vue-router'

export enum Routes {
  MAIN = 'MAIN',
  DASHBOARD = 'DASHBOARD',
  SCHEDULE = 'SCHEDULE',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  STATISTICS = 'STATISTICS',
  EXERCISE_CALENDAR = 'EXERCISE_CALENDAR',
  PROFILE = 'PROFILE',
  EXERCISES = 'EXERCISES',
  USERS = 'USERS',
  TRAINERS = 'TRAINERS',
  TRAINERS_STATISTICS = 'TRAINERS_STATS',
  NOT_FOUND_REDIRECT = 'NOT_FOUND_REDIRECT',
  NOT_FOUND = '404',
}

type RouteRecord = RouteRecordRaw & {
  name: Routes
}

const routes: Array<RouteRecord> = [
  {
    path: '/',
    name: Routes.MAIN,
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/index.vue'),
  },
  {
    path: '/',
    name: Routes.DASHBOARD,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: '/schedule',
        name: Routes.SCHEDULE,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/schedule.vue'),
      },
      {
        path: '/subscriptions',
        name: Routes.SUBSCRIPTIONS,
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/subscriptions.vue'
          ),
      },
      {
        path: '/stats',
        name: Routes.STATISTICS,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/statistics.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/exercises-calendar',
        name: Routes.EXERCISE_CALENDAR,
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/trainings-calendar.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profile/:id?',
        name: Routes.PROFILE,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/profile.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/exercises/',
        name: Routes.EXERCISES,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/trainings.vue'),
        meta: {
          requiresAuth: true,
          // trainer
          roles: [1, 2],
        },
        props: true,
      },
      {
        path: '/trainers',
        name: Routes.TRAINERS,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/users.vue'),
        meta: {
          requiresAuth: true,
          roles: [1],
        },
      },
      {
        path: '/users',
        name: Routes.USERS,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/users.vue'),
        meta: {
          requiresAuth: true,
          roles: [1],
        },
      },
      {
        path: '/trainers-stats',
        name: Routes.TRAINERS_STATISTICS,
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/trainers-statistics.vue'
          ),
        meta: {
          requiresAuth: true,
          roles: [1],
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: Routes.NOT_FOUND_REDIRECT,
    redirect: () => {
      return { name: Routes.NOT_FOUND }
    },
  },
  {
    path: '/404',
    name: Routes.NOT_FOUND,
    component: () =>
      import(/* webpackChunkName: "page-not-found" */ '../views/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    window.scrollTo(0, 0)
  },
})

export default router
