import axios, { AxiosInstance } from 'axios'
import { API_HOST } from '@/config'

const apiClient: AxiosInstance = axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
  withCredentials: false,
})

export default apiClient
