import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import Authorization from "@/components/Authorization.vue";
import BaseNotification from "@/components/BaseNotification.vue";
script.components = Object.assign({}, { Authorization, BaseNotification }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { Authorization, BaseNotification }, script.__o.components);}