export const getFormattedPhone = (value: string): string => {
  let phoneNumber = value.replace(/[^\d]/g, '')
  if (phoneNumber.startsWith('7')) {
    phoneNumber = phoneNumber.replace('7', '')
  }
  const len = phoneNumber.length

  if (len < 4) return `+7 (${phoneNumber}`
  if (len < 7) {
    return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )} ${phoneNumber.slice(6, 10)}`
}
