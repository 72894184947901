import { State } from '@/store'
import { MutationTree } from 'vuex'
import { Format, Page, QA, Review, TrainerBio } from '@/types/content'

export type CmsState = {
  landing?: Page
  schedule?: Page
  login?: Page
  splash?: Page
  subscription?: Page
  buy?: Page
  exercises?: Page
  statistics?: Page
  account?: Page
  error?: Page
  'trainer-account'?: Page
  'coming-exercises'?: Page
  'attended-mark'?: Page
  'add-exercises'?: Page
  'member-list'?: Page
  'add-member'?: Page
  'exercises-list'?: Page
  'past-exercises'?: Page
  'trainer-stat'?: Page
  'trainers-stat'?: Page

  trainersBios?: TrainerBio[]
  reviews?: Review[]
  formats?: Format[]
  faqs: {
    // LANDING = 0
    // SUBSCRIPTION = 1
    landing?: QA[]
    subscription?: QA[]
  }
}

export const cmsInitialState: CmsState = {
  faqs: {},
}

export enum CmsMutationTypes {
  SET_PAGE = 'SET_PAGE',
  SET_TRAINERS_BIOS = 'SET_TRAINERS_BIOS',
  SET_REVIEWS = 'SET_REVIEWS',
  SET_FAQ = 'SET_FAQ',
  SET_FORMATS = 'SET_FORMATS',
}

export type CmsMutations = {
  [CmsMutationTypes.SET_PAGE](s: State, page: Page): void
  [CmsMutationTypes.SET_TRAINERS_BIOS](s: State, bios: TrainerBio[]): void
  [CmsMutationTypes.SET_REVIEWS](s: State, reviews: Review[]): void
  [CmsMutationTypes.SET_FORMATS](s: State, formats: Format[]): void
  [CmsMutationTypes.SET_FAQ](
    s: State,
    props: {
      type: 'landing' | 'subscription'
      faq: QA[]
    }
  ): void
}

export const cmsMutations: MutationTree<State> & CmsMutations = {
  [CmsMutationTypes.SET_PAGE]({ cms }, page) {
    cms[page.url] = page
    localStorage.setItem(page.url, JSON.stringify(page))
  },
  [CmsMutationTypes.SET_TRAINERS_BIOS]({ cms }, bios) {
    cms.trainersBios = bios
    localStorage.setItem('trainers-bios', JSON.stringify(bios))
  },
  [CmsMutationTypes.SET_FORMATS]({ cms }, formats) {
    cms.formats = formats
    localStorage.setItem('formats', JSON.stringify(formats))
  },
  [CmsMutationTypes.SET_REVIEWS]({ cms }, reviews) {
    cms.reviews = reviews
    localStorage.setItem('reviews', JSON.stringify(reviews))
  },
  [CmsMutationTypes.SET_FAQ]({ cms }, { type, faq }) {
    cms.faqs[type] = faq
    localStorage.setItem('faqs', JSON.stringify(cms.faqs))
  },
}

// export enum CmsActionTypes {
//   FETCH_PAGE = 'FETCH_PAGE',
// }

// export interface CmsActions {
//   [CmsActionTypes.FETCH_PAGE](
//     { commit }: AugmentedActionContext,
//     pageURL: string
//   ): Promise<Page | Error>
// }

// export const cmsActions: ActionTree<State, State> & CmsActions = {
//   async [CmsActionTypes.FETCH_PAGE]({ commit }, pageURL) {
//     try {
//       const { data } = await ContentService.getPage(pageURL)
//       // TODO
//       return Promise.resolve(data)
//     } catch (e) {
//       return handleError(e)
//     }
//   },
// }
