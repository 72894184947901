import axios, { AxiosError } from 'axios'
import { ServerError } from './auth.service.types'
// TODO: now not only auth

export const isAxiosError = (e: unknown): e is AxiosError => {
  if (axios.isAxiosError(e)) {
    return true
  }
  return false
}

export const isServerError = (error: unknown): error is ServerError => {
  const e = error
  if (e && isAxiosError(e)) {
    if ('response' in e && e.response) {
      const { response } = e
      if (
        'data' in response &&
        response.data &&
        typeof response.data === 'object'
      ) {
        const { data } = response
        if ('errors' in data) {
          return true
        }
      }
    }
  }
  return false
}

export const getErrorsList = (e: ServerError): string[] => {
  const errors = Object.values(e.response.data.errors)
  console.log('error list', errors)
  const errorsList: string[] = []
  errors.forEach((er) => {
    if (Array.isArray(er)) {
      errorsList.push(...er)
    } else {
      errorsList.push(er)
    }
  })
  return errorsList
}
