import script from "./FormRegister.vue?vue&type=script&setup=true&lang=ts"
export * from "./FormRegister.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BaseInput from "@/components/BaseInput.vue";
import FormErrors from "@/components/FormErrors.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
script.components = Object.assign({}, { BaseInput, FormErrors, PrimaryButton }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BaseInput, FormErrors, PrimaryButton }, script.__o.components);}