import { allEndpoints, allDynamicEndpoints } from '@/config/endpoints'
import { AdminExercise, ExercisesDictionaries } from '@/types/trainings'
import { Member, Trainer, MembersStatistics } from '@/types/users'
import apiClient from './client'
import {
  AddMemberRequest,
  AddTrainerRequest,
  GetMembersResponseData,
  GetExercisesResponseData,
  AddExerciseRequest,
  AddExerciseResponseData,
  GetTrainersShortsResponseData,
  getMemberSubscriptionsResponseData,
  MembersActivationData,
  GetSubscriptionsDictionariesData,
} from './admin.service.types'
import { TrainersReportTotal, TrainersReports } from '@/types/report'
import { Subscription } from '@/types/subscriptions'
import { ExercisesStatistics } from '@/types/statistics'
import { startProgressBar } from '@/use/progressBar'

const endpoints = allEndpoints.admin
const dynamicEndpoints = allDynamicEndpoints.admin

export const AdminService = {
  getMembers(
    params: Record<string, boolean | number> = {}
  ): Promise<{ data: GetMembersResponseData }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: endpoints.members,
      params,
    })
  },

  async getMember(id: string): Promise<{ data: Member }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.member(id),
    })
  },

  searchMembers(
    searchStr: string,
    params: Record<string, boolean | number>
  ): Promise<{ data: GetMembersResponseData }> {
    // TODO: different progress bar
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.searchMembers(searchStr),
      params,
    })
  },

  getMemberSubscriptions(
    memberId: string
  ): Promise<{ data: getMemberSubscriptionsResponseData }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.memberSubscriptions(memberId),
    })
  },

  getSubscriptionsDictionaries(): Promise<{
    data: GetSubscriptionsDictionariesData
  }> {
    return apiClient({
      method: 'get',
      url: endpoints.subscriptionsDictionaries,
    })
  },

  editSubscription(
    id: string,
    data: Partial<Subscription>
  ): Promise<{ data: Subscription }> {
    return apiClient({
      method: 'patch',
      url: dynamicEndpoints.subscription(id),
      data,
    })
  },

  subscriptionChangeState(
    id: string,
    state: number
  ): Promise<{ data: { state: number } }> {
    return apiClient({
      method: 'post',
      url: dynamicEndpoints.subscriptionChangeState(id),
      data: { state },
    })
  },

  addMember(data: AddMemberRequest): Promise<{ data: Member }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.members,
      data,
    })
  },

  addTrainer(data: AddTrainerRequest): Promise<{ data: Trainer }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.trainers,
      data,
    })
  },

  deleteUser(id: string): Promise<{ data: string }> {
    return apiClient({
      method: 'delete',
      url: dynamicEndpoints.members(id),
    })
  },

  editTrainer(id: string, data: Partial<Trainer>): Promise<{ data: Trainer }> {
    return apiClient({
      method: 'patch',
      url: dynamicEndpoints.trainer(id),
      data,
    })
  },

  editMember(id: string, data: Partial<Member>): Promise<{ data: Member }> {
    return apiClient({
      method: 'patch',
      url: dynamicEndpoints.member(id),
      data,
    })
  },

  // depricated
  // editMemberExercisesQuantities(
  //   id: string,
  //   data: ExercisesQuantitiesServer
  // ): Promise<{ data: ExercisesQuantitiesServer }> {
  //   return apiClient({
  //     method: 'post',
  //     url: dynamicEndpoints.changeExercisesQunatities(id),
  //     data,
  //   })
  // },

  membersActivateMany(
    data: MembersActivationData
  ): Promise<{ data: MembersActivationData }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.membersActivateMany,
      data,
    })
  },

  membersDeactivateMany(
    data: MembersActivationData
  ): Promise<{ data: MembersActivationData }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.membersDeactivateMany,
      data,
    })
  },

  // TODO: actually getting Trainer, not TrainerShorts
  getTrainersShorts(
    params: Record<string, string | boolean | number> = {}
  ): Promise<{ data: GetTrainersShortsResponseData }> {
    return apiClient({
      method: 'get',
      url: endpoints.trainers,
      params,
    })
  },

  // depricated
  // getExercisesByDate(
  //   date: string
  // ): Promise<{ data: GetExercisesResponseData }> {
  //   return apiClient({
  //     method: 'get',
  //     url: dynamicEndpoints.exercisesByDate(date),
  //   })
  // },

  getExercisesByDateRange(
    from_date: string,
    to_date: string
  ): Promise<{ data: GetExercisesResponseData }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.exercisesByDateRange(from_date, to_date),
    })
  },

  getExercise(id: string): Promise<{ data: AdminExercise }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.exercises(id),
    })
  },

  addExercise(
    data: AddExerciseRequest
  ): Promise<{ data: AddExerciseResponseData }> {
    startProgressBar()
    return apiClient({
      method: 'post',
      url: endpoints.exercises,
      data,
    })
  },

  editExercise(
    id: string,
    data: AddExerciseRequest
  ): Promise<{ data: AddExerciseResponseData }> {
    return apiClient({
      method: 'patch',
      url: dynamicEndpoints.exercises(id),
      data,
    })
  },

  deleteExercise(id: string): Promise<{ data: string }> {
    return apiClient({
      method: 'delete',
      url: dynamicEndpoints.exercises(id),
    })
  },

  getExerciseDictionaries(): Promise<{ data: ExercisesDictionaries }> {
    return apiClient({
      method: 'get',
      url: endpoints.exercisesDictionaries,
    })
  },

  getMembersStatistics(): Promise<{ data: MembersStatistics }> {
    return apiClient({
      method: 'get',
      url: endpoints.membersStatistics,
    })
  },

  getTrainerStatistics(
    id: string,
    year: string,
    month: string
  ): Promise<{ data: ExercisesStatistics }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.trainerStatistics(id, year, month),
    })
  },

  getMemberStatistics(
    id: string,
    year: string,
    month: string
  ): Promise<{ data: ExercisesStatistics }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.memberStatistics(id, year, month),
    })
  },

  getMemberExercisesPast(
    id: string,
    params: Record<string, number>
  ): Promise<{ data: GetExercisesResponseData }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.memberExercisesPast(id),
      params,
    })
  },

  getMemberExercisesComing(
    id: string,
    params: Record<string, number>
  ): Promise<{ data: GetExercisesResponseData }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.memberExercisesComing(id),
      params,
    })
  },

  getTrainerExercisesPast(
    id: string,
    params: Record<string, number>
  ): Promise<{ data: GetExercisesResponseData }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.trainerExercisesPast(id),
      params,
    })
  },

  getTrainerExercisesComing(
    id: string,
    params: Record<string, number>
  ): Promise<{ data: GetExercisesResponseData }> {
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.trainerExercisesComing(id),
      params,
    })
  },

  getReportAllPeriod(
    params: Record<string, number>
  ): Promise<{ data: TrainersReports }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: endpoints.reportAllPeriod,
      params,
    })
  },

  getReportDateBetween(
    from_date: string,
    to_date: string,
    params: Record<string, number>
  ): Promise<{ data: TrainersReports }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: dynamicEndpoints.reportDateBetween(from_date, to_date),
      params,
    })
  },

  getReportTotal(): Promise<{ data: TrainersReportTotal }> {
    startProgressBar()
    return apiClient({
      method: 'get',
      url: endpoints.reportTotal,
    })
  },
}
