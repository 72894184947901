import client from '@/services/client'
import {
  DeleteAccountResponse,
  EditProfileResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  RegisterResponseData,
  ResendVerifyPhoneRequest,
  ResendVerifyPhoneResponse,
  VerifyPhoneRequest,
  VerifyPhoneResponse,
} from '@/services/auth.service.types'
import { User } from '@/types/users'
import { allEndpoints } from '@/config/endpoints'
import { startProgressBar } from '@/use/progressBar'
const endpoints = allEndpoints.auth

// api
// http://api.warmice.along.pw/doc/

export const AuthService = {
  async login(data: LoginRequest): Promise<LoginResponse> {
    startProgressBar()
    const response = await client({
      method: 'post',
      url: endpoints.login,
      data,
    })
    return response
  },

  register(data: RegisterRequest): Promise<RegisterResponse> {
    startProgressBar()
    return client({
      method: 'post',
      url: endpoints.register,
      data,
    })
  },

  verifyPhone(data: VerifyPhoneRequest): Promise<VerifyPhoneResponse> {
    startProgressBar()
    return client({
      method: 'post',
      url: endpoints.verifyPhone,
      data,
    })
  },

  resendVerifyPhone(
    data: ResendVerifyPhoneRequest
  ): Promise<ResendVerifyPhoneResponse> {
    startProgressBar()
    return client({
      method: 'post',
      url: endpoints.resendVerifyPhone,
      // TODO
      data: { ...data, code: '2323' },
    })
  },

  editProfile(data: Partial<User>): Promise<EditProfileResponse> {
    startProgressBar()
    return client({
      method: 'patch',
      url: endpoints.profile,
      data,
    })
  },

  deleteAccount(): Promise<DeleteAccountResponse> {
    startProgressBar()
    return client({
      method: 'delete',
      url: endpoints.profile,
    })
  },

  resetPassword(data: {
    phone: string
  }): Promise<{ data: RegisterResponseData }> {
    startProgressBar()
    return client({
      method: 'post',
      url: endpoints.resetPassword,
      data,
    })
  },

  resetPasswordConfirm(data: {
    phone: string
    stt: string
    password: string
    code: string
  }): Promise<{ data: { detail: string } }> {
    startProgressBar()
    return client({
      method: 'post',
      url: endpoints.resetPasswordConfirm,
      data,
    })
  },
}
