export default {
  routes: {
    mainPage: 'Главная',
    schedule: 'Расписание',
    subscriptions: 'Абонементы',
    profile: 'Профиль',
    users: 'Пользователи',
    trainers: 'Тренеры',
    statistics: 'Статистика',
    exercises: 'Тренировки',
    trainingCalendar: 'Календарь тренировок',
  },

  buttons: {
    login: 'Войти',
    buy: 'Приобрести',
    goBack: 'Назад',
    buySubscription: 'Купить абонемент',
    create: 'Создать',
  },

  inputs: {
    name: 'Имя',
    phone: 'Телефон',
    email: 'Email',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    code: 'Код',
  },

  dashboard: {
    actionModal: {
      prompt: 'Вы уверены, что хотите удалить аккаунт?',
      btnAction: 'Удалить',
      btnCancel: 'Отмена',
    },
    editMemberModal: {
      prompt: 'Отредактируйте информацию',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        email: '@:inputs.email',
      },
      btnSaveSrOnly: 'Сохранить',
    },
    header: {
      mainPage: '@:routes.mainPage',
      users: '@:routes.users',
      trainers: '@:routes.trainers',
      statistics: '@:routes.statistics',
      exercises: '@:routes.exercises',
      exercisesCalendar: '@:routes.trainingCalendar',
      subscriptions: '@:routes.subscriptions',
      btnLogin: '@:buttons.login',
      profile: '@:routes.profile',
    },
    formTraining: {
      inputs: {
        name: 'Название тренировки',
        trainer: 'Тренер',
        timeFrom: 'с 17:00',
        timeTo: 'до 18:00',
        location: 'Место',
        exerciseType: 'Тип тренировки',
        maxMembers: 'Макс участников',
      },
      changeExercise: 'Измените тренировку',
      createExercise: 'Добавьте тренировку',
      btnChange: 'Изменить',
      btnCreate: '@:buttons.create',
    },
    formUser: {
      addUser: 'Добавьте пользователя',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        asTrainer: 'На правах тренера',
        password: '@:inputs.password',
        ratePersonal: 'Ставка на индивидуальные занятия',
        rateGroup: 'Ставка на групповые занятия',
      },
      btnCreate: '@:buttons.create',
    },
  },

  auth: {
    welcome: 'Добро пожаловать в “Тёплый лёд”!',
    forgotPassword: 'Забыли пароль?',
    login: {
      title: 'Школу-студию по фигурному катанию и танцам на льду для взрослых!',
      dontHaveAccountQuestion: 'Еще нет аккаунта?',
      btnGoRegister: 'Зарегистрируйтесь',
      btnLogin: '@:buttons.login',
      inputs: {
        phone: '@:inputs.phone',
        password: '@:inputs.password',
      },
      forgotPassword: '@:auth.forgotPassword',
    },
    register: {
      // title: 'Зарегистрируйтесь, чтобы посетить свой первый урок',
      title:
        '«Тёплый лёд», Школу-студию по фигурному катанию и танцам на льду для взрослых!',
      alreadyRegisteredQuestion: 'Уже зарегистрировались?',
      btnGoLogin: '@:buttons.login',
      btnRegister: 'Зарегистрироваться',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        password: '@:inputs.password',
        repeatPassword: '@:inputs.repeatPassword',
      },
      forgotPassword: '@:auth.forgotPassword',
    },
    phoneVerification: {
      title: 'Подтвердите ваш номер телефона!',
      weSentCodeToYourPhone:
        'Мы направили 4-х значный код на ваш номер телефона',
      btnConfirm: 'Подтвердить',
      codeCanBeResentIn: 'Отправить код повторно можно через ',
      resendCode: 'Отправить код повторно',
      forgotPassword: '@:auth.forgotPassword',
      inputs: {
        code: '@:inputs.code',
      },
    },
    resetPassword: {
      title: 'Восстановление пароля',
      btnGoLogin: '@:auth.register.btnGoLogin',
      phoneNumber: 'Номер телефона',
      codeAndPasswordsFor: 'Код и новый пароль для',
      btnSend: 'Отправить',
      btnReset: 'Восстановить',
      btnPhoneAgain: 'Ввести телефон заново',
      passwordHaveBeenChanged: 'Пароль был изменен',
      inputs: {
        phone: '@:inputs.phone',
        password: '@:inputs.password',
        repeatPassword: '@:inputs.repeatPassword',
        code: '@:inputs.code',
      },
    },
  },

  schedule: {
    title: 'Расписание',
    address: 'Пражская, д.10, м.Бухарестская',
  },

  profile: {
    welcome: 'Рады вас видеть,',
    yourData: 'Ваши данные',
    editAccount: 'Редактировать аккаунт',
    deleteAccount: 'Удалить аккаунт',
    logout: 'Выйти',
    yourExercisesToday: 'Ваши тренировки на сегодня',
    yourExercises: 'Ваши тренировки',
  },

  subscriptions: {
    justRegistered: {
      congratulations: 'Поздравляем!',
      youSuccessfullyRegistered:
        'Вы успешно зарегистрировались в личном кабинете Теплого льда!',
      great: 'Супер!',
    },
    subscriptions: 'Абонементы',
    prompt: 'Выберите абонемент и назначьте свою тренировку!',
    noAvailableSubscriptoins:
      'У вас нет действующих абонементов в данный момент',
    btnBuy: '@:buttons.buy',
    book: {
      booking: 'Заказ',
      personalExercises: 'Индивидуальные занятия',
      buyingSubscription: 'Покупка абонемента в спортивную школу',
      quantity: 'Количество',
      overall: 'Итого:',
      doYouHaveQuestions: 'У вас есть вопросы?',
      yourDataWillBeUsed:
        'Ваши личные данные будут использоваться для обработки ваших заказов, упрощения вашей работы с сайтом и для других целей, описанных в нашей политика конфиденциальности.',
      privacyPolicy: {
        label: 'Я прочитал(а) и соглашаюсь с правилами сайта {0}',
        name: 'правила и условия*',
      },
      siteRules: {
        label: 'Согласен с {0}',
        name: 'политикой конфиденциальности',
      },
      btnBuy: '@:buttons.buy',
      btnGoBack: '@:buttons.goBack',
      frequentQuestions: 'Частые вопросы',
    },
  },

  exercisesCalendar: {
    exercises: 'Тренировки',
    groupExercisesRemaining: 'групповых тренировок осталось',
    youHaveUnlimitedGroupExercises: 'У вас безлимитные групповые тренировки',
    personalExercisesRemaining: 'индивидуальных тренировок осталось',
    youHaveUnlimitedPersonalExercises:
      'У вас безлимитные индивидуальные тренировки',
    btnAddUp: 'Пополнить',
    youHaveNoExercisesLeft: 'У вас не осталось {exerciseType} тренировок!',
    btnBuySubscription: '@:buttons.buySubscription',
    exerciseType: {
      personal: 'персональных',
      group: 'групповых',
    },
  },

  userStatistics: {
    statistics: 'Статистика',
    userStatistics: 'Статистика пользователя',
    trainerStatistics: 'Статистика тренера',
    pendingExercises: 'Вас ожидает',
    pastExercises: 'Предыдущие тренировки',
    missed: 'Не посещена',
    donut: {
      ofExercises: 'тренировок ',
      itIs: 'Это',
      ofResults: 'результата ',
    },
  },

  adminExercises: {
    title: 'Тренировки',
    weekday: {
      noSchedule: 'Расписание отсутствует',
      details: 'Подробнее',
      ofPeopleShort: 'чел.',
    },
    trainer: 'Тренер',
    ofParticipants: 'участников',
    btnCancel: 'Отменить',
  },

  trainerExercises: {
    title: 'Тренировки',
    ofStudents: 'участник | участников',
    attendance: 'Посещение',
    wasPresent: 'Явился',
    wasAbsent: 'не явился',
    btnGoBack: '@:buttons.goBack',
  },

  trainersReport: {
    title: 'Статистика',
    nav: {
      period: 'Период',
      today: 'Сегодня',
      yesterday: 'Вчера',
      allTime: 'За все время',
      total: 'Общий отчет',
    },
    table: {
      name: 'Имя',
      email: 'e-mail',
      hours: 'Часы',
      exercises: 'Тренировки',
      truancies: 'Пропуски',
      rate: 'Ставка',
      payment: 'Оплата',
      personalShort: 'инд.',
      groupShort: 'групп.',
    },
    total: {
      trainersCount: 'Кол-во тренеров',
      hours: 'Часы',
      exercises: 'Тренировки',
      payment: 'Оплата',
    },
    editTrainerRate: {
      // TODO
      rate: 'Ставка',
      btnSave: 'Сохранить',
    },
  },

  users: {
    search: 'Поиск...',
    ofPaidUsers: 'Оплаченных пользователей',
    trainers: 'Тренеры',
    users: 'Пользователи',
    active: 'Незаблокированные',
    blocked: 'Заблокированные',
    block: 'заблокировать',
    unblock: 'разблокировать',
    name: 'Имя',
    phone: 'Телефон',
    exerciesGroupAndPersonal: 'Тренировки (инд / групп)',
    subscription: 'Абонемент',
    actions: 'Действия',
    notPaid: 'Не оплачено',
    paid: 'Оплачено',
    noSubscriptionShort: 'б/а',
    youSureYouWantToUsers: 'Вы уверены, что хотите {action} пользователей?',
    ordersModal: {
      title: 'Список заказов пользователя',
      subscription: 'Абонемент',
      date: 'Дата',
      expires: 'Истекает',
      status: 'Статус',
      exercises: 'Тренировки',
      paymentMethod: 'Способ оплаты',
      editSubscriptionExerciseCount: {
        exercisesCountShort: 'кол-во тренировок',
        btnSave: 'Сохранить',
      },
    },
  },

  landing: {
    header: {
      mainPage: '@:routes.mainPage',
      schedule: '@:routes.schedule',
      subscriptions: '@:routes.subscriptions',
      btnBuySubscription: '@:buttons.buySubscription',
      btnLogin: '@:buttons.login',
    },

    contactUs: {
      prompt: 'Оставьте заявку и наши специалисты с Вами свяжутся!',
      form: {
        inputs: {
          name: '@:inputs.name',
          phone: '@:inputs.phone',
          email: '@:inputs.email',
        },
        btnSubmitApplication: 'Оставить заявку',
        errorsMessage:
          'Есть ошибки в заполнении, проверьте правильность введенных данных',
        applicationSubmittedSuccessfully: 'Заявка успешно отправлена!',
      },
      policy: {
        paragraph: 'Нажимая на кнопку «Оставить заявку», вы принимаете {0}',
        name: '«Соглашение об обработке персональных данных» ',
      },
      process: {
        title: 'Как проходит Ваш вводный урок:',
        determineLevel: 'Определим ваш уровень',
        getAcquainted: 'Познакомимся со школой',
        tellOfSchool: 'Расскажем о занятиях',
        pickATrainer: 'Подберем тренера',
      },
    },

    how: {
      title: 'Как проходит обучение в школе «Теплый лед»? ',
    },
    trainers: {
      title: 'Подбираем преподавателя под ваш уровень катания',
    },
    formats: {
      title: 'Учитесь в удобном для вас формате',
      btnAction: 'Оставить заявку',
    },
    advanced: {
      title:
        'Если захотите чего-то особенного, то попробуйте продвинутые программы',
      details: 'Подробнее',
    },
    students: {
      title: 'Что говорят наши ученики',
    },

    footer: {
      mainPage: '@:routes.mainPage',
      schedule: '@:routes.schedule',
      subscriptions: '@:routes.subscriptions',
      profile: '@:routes.profile',
      warmIce: '© Теплый лед',
    },
  },

  '404': {
    youAreLost: 'кажется, ты заблудился',
    pageNotFound: 'страница не найдена',
  },
}
