import { computed, ComputedRef, WritableComputedRef } from 'vue'
import { Composer, useI18n, VueMessageType } from 'vue-i18n'

export const useLocale = (prefix = ''): UseLocaleReturnType => {
  const i18n = useI18n()
  return {
    l: (keyword: string, options: Record<string, string> = {}) =>
      prefix
        ? i18n.t(`${prefix}.${keyword}`, options)
        : i18n.t(keyword, options),
    i18n,
    locale: computed(() => i18n.locale.value),
  }
}

export type UseLocaleReturnType = {
  l: (k: string, options?: Record<string, string>) => string
  i18n: Composer<unknown, unknown, unknown, VueMessageType>
  locale: ComputedRef<string>
}
