import { ContentService } from '@/services/content.service'
import { CmsMutationTypes } from '@/store/cms.module'
import { Page, PageDictionary, PageUrl } from '@/types/content'
import { computed, ComputedRef } from 'vue'
import { handleErrorStore, store } from './store'

export const usePage = (pageURL: PageUrl): UsePageReturnType => {
  const fetchPage = async () => {
    try {
      const { data } = await ContentService.getPage(pageURL)
      store.commit(CmsMutationTypes.SET_PAGE, data)
      console.log('fetch page', pageURL, data)
    } catch (e) {
      handleErrorStore(e)
    }
  }

  const page = computed(() => store.state.cms[pageURL])
  const d = computed(() => page.value?.dictionary)

  return {
    page,
    fetchPage,
    d,
  }
}

export type UsePageReturnType = {
  page: ComputedRef<Page | undefined>
  fetchPage: () => void
  d: ComputedRef<PageDictionary | undefined>
}
