export default {
  routes: {
    mainPage: 'Main',
    schedule: 'Schedule',
    subscriptions: 'Subscriptions',
    profile: 'Profile',
    users: 'Users',
    trainers: 'Trainers',
    statistics: 'Statistics',
    exercises: 'Exercises',
    trainingCalendar: 'Exercises Calendar',
  },

  buttons: {
    login: 'Login',
    buy: 'Buy',
    goBack: 'Back',
    buySubscription: 'Buy Subscription',
    create: 'Create',
  },

  inputs: {
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    code: 'Code',
  },

  dashboard: {
    actionModal: {
      prompt: 'Are you sure you want to delete your account?',
      btnAction: 'Delete',
      btnCancel: 'Cancel',
    },
    editMemberModal: {
      prompt: 'Edit',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        email: '@:inputs.email',
      },
      btnSaveSrOnly: 'Save',
    },
    header: {
      mainPage: '@:routes.mainPage',
      users: '@:routes.users',
      trainers: '@:routes.trainers',
      statistics: '@:routes.statistics',
      exercises: '@:routes.exercises',
      exercisesCalendar: '@:routes.trainingCalendar',
      subscriptions: '@:routes.subscriptions',
      btnLogin: '@:buttons.login',
      profile: '@:routes.profile',
    },
    formTraining: {
      inputs: {
        name: 'Exercise name',
        trainer: 'Trainer',
        timeFrom: 'from 17:00',
        timeTo: 'to 18:00',
        location: 'Location',
        exerciseType: 'Exercise type',
        maxMembers: 'Max members count',
      },
      changeExercise: 'Edit exercise',
      createExercise: 'Add exercise',
      btnChange: 'Save',
      btnCreate: '@:buttons.create',
    },
    formUser: {
      addUser: 'Add user',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        asTrainer: 'As trainer',
        password: '@:inputs.password',
        ratePersonal: 'Rate for personal classes',
        rateGroup: 'Rate for group classes',
      },
      btnCreate: '@:buttons.create',
    },
  },

  auth: {
    welcome: 'Welcome to "Warm Ice"',
    forgotPassword: 'Forgot password?',
    login: {
      title: 'School-studio for figure skating and ice dancing for adults!',
      dontHaveAccountQuestion: "Don't have an account yet?",
      btnGoRegister: 'Register',
      btnLogin: '@:buttons.login',
      inputs: {
        phone: '@:inputs.phone',
        password: '@:inputs.password',
      },
      forgotPassword: '@:auth.forgotPassword',
    },
    register: {
      title: 'Register to attend your first class',
      alreadyRegisteredQuestion: 'Alreade registered?',
      btnGoLogin: '@:buttons.login',
      btnRegister: 'Register',
      inputs: {
        name: '@:inputs.name',
        phone: '@:inputs.phone',
        password: '@:inputs.password',
        repeatPassword: '@:inputs.repeatPassword',
      },
      forgotPassword: '@:auth.forgotPassword',
    },
    phoneVerification: {
      title: 'Verify your phone number!',
      weSentCodeToYourPhone: 'We sent 4 digits code to your phone',
      btnConfirm: 'Verify',
      codeCanBeResentIn: 'You can resend the code in ',
      resendCode: 'Resend verification code',
      forgotPassword: '@:auth.forgotPassword',
      inputs: {
        code: '@:inputs.code',
      },
    },
    resetPassword: {
      title: 'Password Reset',
      btnGoLogin: '@:auth.register.btnGoLogin',
      phoneNumber: 'Phone number',
      codeAndPasswordsFor: 'Code and new password for',
      btnSend: 'Send',
      btnReset: 'Reset',
      btnPhoneAgain: 'Type phone again',
      passwordHaveBeenChanged: 'Password has been changed',
      inputs: {
        phone: '@:inputs.phone',
        password: '@:inputs.password',
        repeatPassword: '@:inputs.repeatPassword',
        code: '@:inputs.code',
      },
    },
  },

  schedule: {
    title: 'Schedule',
    address: 'Prajsky street, 10, Buharestkaya',
  },

  profile: {
    welcome: 'Happy to see you, ',
    yourData: 'Your profile',
    editAccount: 'Edit profile',
    deleteAccount: 'Delete account',
    logout: 'Exit',
    yourExercisesToday: 'Your exercises for today',
    yourExercises: 'Your exercises',
  },

  subscriptions: {
    justRegistered: {
      congratulations: 'Congratulations!',
      youSuccessfullyRegistered: "You've successfully registered",
      great: 'Hooray!',
    },
    subscriptions: 'Subscriptions',
    prompt: 'Pick a subscription and book an exercise',
    noAvailableSubscriptoins: 'You have no working subscriptions',
    btnBuy: '@:buttons.buy',
    book: {
      booking: 'Booking',
      personalExercises: 'Personal classes',
      buyingSubscription: 'Purchase of a sports school subscription',
      quantity: 'Quantity',
      overall: 'Overall:',
      doYouHaveQuestions: 'Do you have questions',
      yourDataWillBeUsed:
        'Ваши личные данные будут использоваться для обработки ваших заказов, упрощения вашей работы с сайтом и для других целей, описанных в нашей политика конфиденциальности.',
      privacyPolicy: {
        label: 'Я прочитал(а) и соглашаюсь с правилами сайта {0}',
        name: 'правила и условия*',
      },
      siteRules: {
        label: 'Согласен с {0}',
        name: 'политикой конфиденциальности',
      },
      btnBuy: '@:buttons.buy',
      btnGoBack: '@:buttons.goBack',
      frequentQuestions: 'Частые вопросы',
    },
  },

  exercisesCalendar: {
    exercises: 'Exercises',
    groupExercisesRemaining: 'group exercises remaining',
    youHaveUnlimitedGroupExercises: 'You have unlimimited group exercises',
    personalExercisesRemaining: 'personal exercises remaining',
    youHaveUnlimitedPersonalExercises:
      'You have unlimimited personal exercises',
    btnAddUp: 'Add UP',
    youHaveNoExercisesLeft: 'You have no {exerciseType} exercises left!',
    btnBuySubscription: '@:buttons.buySubscription',
    exerciseType: {
      personal: 'personal',
      group: 'group',
    },
  },

  userStatistics: {
    statistics: 'Statistics',
    userStatistics: 'Member statistics',
    trainerStatistics: 'Trainer statistics',
    pendingExercises: 'Coming exercises',
    pastExercises: 'Past exercises',
    missed: 'Not attended',
    donut: {
      ofExercises: 'exercises',
      itIs: 'It is',
      ofResults: 'results',
    },
  },

  adminExercises: {
    title: 'Exercises',
    weekday: {
      noSchedule: 'No schedule',
      details: 'Details',
      ofPeopleShort: 'mem.',
    },
    trainer: 'Trainer',
    ofParticipants: 'participants',
    btnCancel: 'Cancel',
  },

  trainerExercises: {
    title: 'Exercises',
    ofStudents: 'participant | participants',
    attendance: 'Attendance',
    wasPresent: 'Present',
    wasAbsent: 'Absent',
    btnGoBack: '@:buttons.goBack',
  },

  trainersReport: {
    title: 'Statistics',
    nav: {
      period: 'Period',
      today: 'Today',
      yesterday: 'Yesterday',
      allTime: 'All Time',
      total: 'Total',
    },
    table: {
      name: 'Name',
      email: 'e-mail',
      hours: 'Time',
      exercises: 'Exercises',
      truancies: 'Truancies',
      rate: 'Rate',
      payment: 'Payment',
      personalShort: 'per.',
      groupShort: 'grp.',
    },
    total: {
      trainersCount: 'Trainers Number',
      hours: 'Time',
      exercises: 'Exercises',
      payment: 'Payment',
    },
    editTrainerRate: {
      // TODO
      rate: 'Rate',
      btnSave: 'Save',
    },
  },

  users: {
    search: 'Search...',
    ofPaidUsers: 'Payed Users',
    trainers: 'Trainers',
    users: 'Members',
    active: 'Active',
    blocked: 'Blocked',
    block: 'block',
    unblock: 'unblock',
    name: 'Name',
    phone: 'Phone',
    exerciesGroupAndPersonal: 'Exercises (per. / grp.)',
    subscription: 'Subscription',
    actions: 'Actions',
    notPaid: 'Not Paid',
    paid: 'Paid',
    noSubscriptionShort: 'n/s',
    youSureYouWantToUsers: 'Are your sure you want to {action} users?',
    ordersModal: {
      title: 'User order list',
      subscription: 'Subscription',
      date: 'Date',
      expires: 'Expires',
      status: 'Status',
      exercises: 'Exercsises',
      paymentMethod: 'Payment method',
      editSubscriptionExerciseCount: {
        exercisesCountShort: 'exercises number',
        btnSave: 'Save',
      },
    },
  },

  landing: {
    header: {
      mainPage: '@:routes.mainPage',
      schedule: '@:routes.schedule',
      subscriptions: '@:routes.subscriptions',
      btnBuySubscription: 'Buy Subscription',
      btnLogin: '@:buttons.login',
    },
    contactUs: {
      prompt: "Submit an application and we'll call you",
      form: {
        inputs: {
          name: 'Name',
          phone: 'Phone*',
          email: 'Email*',
        },
        btnSubmitApplication: 'Submit an application',
        errorsMessage: 'There are errors in the form',
        applicationSubmittedSuccessfully: 'Application submitted successfully',
      },
      policy: {
        paragraph: 'Нажимая на кнопку «Оставить заявку», вы принимаете {0}',
        name: '«Соглашение об обработке персональных данных» ',
      },
    },
    footer: {
      mainPage: '@:routes.mainPage',
      schedule: '@:routes.schedule',
      subscriptions: '@:routes.subscriptions',
      profile: '@:routes.profile',
    },

    formats: {
      btnAction: 'Submit an application',
    },
  },

  '404': {
    youAreLost: "Seems like you're log",
    pageNotFound: 'Page not found',
  },
}
