let UID = 0

export function useID(): UniqueIDReturn {
  const getID = () => {
    UID++
    return UID
  }
  return {
    getID,
  }
}

export type UniqueIDReturn = {
  getID: () => number
}
