interface Endpoints {
  [k: string]: string
}

interface DynamicsEndpoints {
  [k: string]: (...arg: string[]) => string
}

const auth: Endpoints = {
  profile: '/profile/',
  login: '/login/',
  register: '/signup/',
  verifyPhone: '/verify/',
  resendVerifyPhone: '/resend-verification/',
  resetPassword: '/reset-password/',
  resetPasswordConfirm: '/reset-password/confirm/',
}

const admin: Endpoints = {
  members: '/administrator/members/',
  member: '/administrator/member/',
  membersStatistics: '/administrator/members/statistics/',
  membersActivateMany: '/administrator/members/activate-many/',
  membersDeactivateMany: '/administrator/members/deactivate-many/',

  trainers: '/administrator/trainers/',
  trainer: '/administrator/trainer/',

  exercises: '/administrator/exercises/',
  exercisesDictionaries: '/administrator/exercises/dictionaries/',

  subscriptions: '/administrator/subscriptions/',
  subscriptionsDictionaries: '/administrator/subscriptions/dictionaries/',

  reportAllPeriod: '/administrator/trainers/report/all-period/',
  reportTotal: '/administrator/trainers/report/total/',
}
const adminDynamic: DynamicsEndpoints = {
  // YYYY-MM-DD
  exercisesByDate: (date) => `/administrator/exercises/by/date/${date}/`,
  exercisesByDateRange: (from_date, to_date) =>
    `/administrator/exercises/by/date-between/${from_date}/${to_date}/`,
  exercises: (id) => `/administrator/exercises/${id}/`,

  member: (id) => `/administrator/members/${id}/`,
  trainer: (id) => `/administrator/trainers/${id}/`,
  changeExercisesQunatities: (id) =>
    `/administrator/members/${id}/change-limits/`,
  searchMembers: (phone) => `/administrator/members/fulltext-search/${phone}/`,
  trainerStatistics: (id, year, month) =>
    `/administrator/trainers/${id}/statistics/${year}/${month}/`,
  memberStatistics: (id, year, month) =>
    `/administrator/members/${id}/statistics/${year}/${month}/`,
  memberExercisesComing: (id) => `/administrator/members/${id}/coming/`,
  memberExercisesPast: (id) => `/administrator/members/${id}/past/`,
  trainerExercisesComing: (id) => `/administrator/trainers/${id}/coming/`,
  trainerExercisesPast: (id) => `/administrator/trainers/${id}/past/`,

  memberSubscriptions: (id) => `/administrator/members/${id}/subscriptions/`,
  subscription: (id) => `/administrator/subscriptions/${id}/`,
  subscriptionChangeState: (id) =>
    `/administrator/subscriptions/${id}/change-state/`,

  reportDateBetween: (from_date, to_date) =>
    `/administrator/trainers/report/date-between/${from_date}/${to_date}/`,
}

const member = {
  myExercsises: '/member/my/exercises/',
  exercisesQuantities: '/member/my/subscriptions/available/',
  bookGroupSubscription: '/member/book-tariff/',
  bookPersonalSubscription: '/member/buy-personal/',
  exercisesComing: '/member/my/exercises/coming/',
  exercisesPast: '/member/my/exercises/past/',
}
const memberDynamic: DynamicsEndpoints = {
  exercisesByDate: (date) => `/member/exercises/by/date/${date}/`,
  bookExercise: (id) => `/member/exercises/${id}/book/`,
  cancelExercise: (id) => `/member/exercises/${id}/book/cancel/`,
  statistics: (year, month) => `/member/statistics/${year}/${month}/`,
}

const trainer = {
  exercisesComing: '/trainer/exercises/coming/',
  exercisesPast: '/trainer/exercises/past/',
}

const trainerDynamic: DynamicsEndpoints = {
  exercisesByDate: (date) => `/trainer/exercises/by/date/${date}/`,
  absent: (exercise_id, member_id) =>
    `/trainer/exercises/${exercise_id}/absent/${member_id}/`,
  present: (exercise_id, member_id) =>
    `/trainer/exercises/${exercise_id}/present/${member_id}/`,
  statistics: (year, month) => `/trainer/statistics/${year}/${month}/`,
}

const content: Endpoints = {
  subscriptions: '/business/tariffs/',
  paymentMethods: '/business/payment-methods/',

  pages: '/cms/pages/',
  trainers: '/cms/trainers/',
  reviews: '/cms/reviews/',

  contactForm: '/cms/application/',
  formats: '/cms/formats/',

  schedule: '/cms/schedule/',
}

const contentDynamic: DynamicsEndpoints = {
  page: (page_url) => `/cms/pages/${page_url}`,
  faq: (type) => `/cms/faq/${type}`,
}

export const allEndpoints = {
  auth,
  admin,
  member,
  trainer,
  content,
}

export const allDynamicEndpoints = {
  admin: adminDynamic,
  member: memberDynamic,
  trainer: trainerDynamic,
  content: contentDynamic,
}
