import { AugmentedActionContext, handleError, State } from '@/store'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import {
  LoginRequest,
  RegisterRequest,
  RegisterResponseData,
} from '@/services/auth.service.types'
import { StorageService } from '@/services/storage.service'
import apiClient from '@/services/client'
import { User } from '@/types/users'
import { AuthService } from '@/services/auth.service'

const setAuthToken = (token: string) => {
  if (token) {
    apiClient.defaults.headers.common['Authorization'] = `Token ${token}`
  } else {
    delete apiClient.defaults.headers.common['Authorization']
  }
}

export type AuthState = {
  user?: User
  phone?: string
  phoneVerificationToken?: string
}

// Make sure user is being set correctly

const user = StorageService.getUser()
setAuthToken(user?.token ? user.token : '')

export const initialState: AuthState = {
  user: user,
  phoneVerificationToken: undefined,
}

export enum AuthMutationTypes {
  SET_USER = 'SET_USER',
  SET_PHONE = 'SET_PHONE',
  SET_PHONE_VERIFICATION_TOKEN = 'SET_PHONE_VERIFICATION_TOKEN',
}

export type AuthMutations = {
  [AuthMutationTypes.SET_USER](s: State, user: User | undefined): void
  [AuthMutationTypes.SET_PHONE](s: State, phone: string): void
  [AuthMutationTypes.SET_PHONE_VERIFICATION_TOKEN](
    s: State,
    token: string
  ): void
}

export const authMutations: MutationTree<State> & AuthMutations = {
  [AuthMutationTypes.SET_USER](state, user) {
    state.auth.user = user
    if (user) {
      StorageService.setUser(user)
      if (user.token) {
        setAuthToken(user.token)
      }
    } else {
      setAuthToken('')
      StorageService.setUser(undefined)
    }
  },
  [AuthMutationTypes.SET_PHONE](state, phone) {
    state.auth.phone = phone
  },
  [AuthMutationTypes.SET_PHONE_VERIFICATION_TOKEN](state, token) {
    state.auth.phoneVerificationToken = token
  },
}

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  VERIFY_PHONE = 'VERIFY_PHONE',
  RESEND_PHONE_VERIFY = 'RESEND_VERIFICATION',
  EDIT_USER = 'EDIT_USE',
  DELETE_USER = 'DELETE_USER',
}

export interface AuthActions {
  [AuthActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    d: LoginRequest
  ): Promise<User | Error>
  [AuthActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void
  [AuthActionTypes.REGISTER](
    { commit }: AugmentedActionContext,
    d: RegisterRequest
  ): Promise<RegisterResponseData | Error>
  [AuthActionTypes.VERIFY_PHONE](
    { commit, state }: AugmentedActionContext,
    code: number
  ): Promise<User | Error>
  [AuthActionTypes.RESEND_PHONE_VERIFY]({
    commit,
    state,
  }: AugmentedActionContext): Promise<RegisterResponseData | Error>
  [AuthActionTypes.EDIT_USER](
    { commit }: AugmentedActionContext,
    editData: Partial<User>
  ): Promise<User | Error>
  [AuthActionTypes.DELETE_USER]({
    commit,
  }: AugmentedActionContext): Promise<string | Error>
}

export const authActions: ActionTree<State, State> & AuthActions = {
  async [AuthActionTypes.LOGIN]({ commit }, requestData) {
    try {
      const { data } = await AuthService.login(requestData)
      console.log('login', data)
      const user: User = data
      commit(AuthMutationTypes.SET_USER, user)
      return Promise.resolve(user)
    } catch (e) {
      return handleError(e)
    }
  },

  async [AuthActionTypes.REGISTER]({ commit }, requestData) {
    try {
      const { data } = await AuthService.register(requestData)
      commit(AuthMutationTypes.SET_PHONE, requestData.phone)
      commit(AuthMutationTypes.SET_PHONE_VERIFICATION_TOKEN, data.stt)
      return Promise.resolve(data)
    } catch (e) {
      return handleError(e)
    }
  },

  async [AuthActionTypes.VERIFY_PHONE]({ commit, state }, code) {
    try {
      const { auth } = state
      if (auth.phone && auth.phoneVerificationToken) {
        const { data } = await AuthService.verifyPhone({
          phone: auth.phone,
          code,
          stt: auth.phoneVerificationToken,
        })
        commit(AuthMutationTypes.SET_USER, data)
        return Promise.resolve(data)
      } else {
        throw new Error('Not enough data to verifyPhone')
      }
    } catch (e) {
      return handleError(e)
    }
  },

  async [AuthActionTypes.RESEND_PHONE_VERIFY]({ commit, state }) {
    try {
      const { auth } = state
      if (auth.user && auth.phoneVerificationToken) {
        const { data } = await AuthService.resendVerifyPhone({
          phone: auth.user.phone,
          stt: auth.phoneVerificationToken,
        })
        console.log('resend data', data)
        commit(AuthMutationTypes.SET_PHONE_VERIFICATION_TOKEN, data.stt)
        return Promise.resolve(data)
      } else {
        throw new Error('Not enough data to verifyPhone')
      }
    } catch (e) {
      return handleError(e)
    }
  },

  async [AuthActionTypes.EDIT_USER]({ commit, state }, editData) {
    try {
      const { data } = await AuthService.editProfile(editData)
      const { auth } = state
      if (auth.user) {
        commit(AuthMutationTypes.SET_USER, {
          ...auth.user,
          ...data,
        })
        return Promise.resolve(auth.user)
      } else {
        throw new Error('No user to edit')
      }
    } catch (e) {
      return handleError(e)
    }
  },

  async [AuthActionTypes.DELETE_USER]({ commit }) {
    try {
      const { data } = await AuthService.deleteAccount()
      commit(AuthMutationTypes.SET_USER, undefined)
      console.log('delete account', data)
      return Promise.resolve(data.detail)
    } catch (e) {
      return handleError(e)
    }
  },

  [AuthActionTypes.LOGOUT]({ commit }) {
    commit(AuthMutationTypes.SET_USER, undefined)
  },
}

export type AuthGetters = {
  loggedIn(s: State): boolean
  isTrainer(s: State): boolean
  isAdmin(s: State): boolean
}

export const authGetters: GetterTree<State, State> & AuthGetters = {
  loggedIn({ auth }: State) {
    return !!auth.user && !!auth.user.phone
  },
  isAdmin({ auth }: State) {
    // return !!auth.user && auth.user.role === 'Administrator'
    return !!auth.user && auth.user.role === 1
  },
  isTrainer({ auth }: State) {
    // return !!auth.user && auth.user.role === 'Trainer'
    return !!auth.user && auth.user.role === 2
  },
}
