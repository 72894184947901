import { computed, createApp, watch } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/main.sass'
import { store } from './store'
import { createI18n } from 'vue-i18n'
import { createMetaManager } from 'vue-meta'
import apiClient from '@/services/client'
import ru from '@/locale/ru'
import en from '@/locale/en'

const i18n = createI18n({
  // legacy: false,
  messages: {
    ru,
    en,
  },
  locale: 'ru',
  fallbackLocale: 'ru',
})

// update accept-language on i18n locale change
const locale = computed(() => i18n.global.locale)
const updateLocal = () => {
  apiClient.defaults.headers.common['accept-language'] = locale.value
}
updateLocal()
watch(locale, () => {
  updateLocal()
})

// access & refresh tokens
import setupInterceptors from '@/services/interceptors'
setupInterceptors(store, router)

const app = createApp(App)

router.beforeEach((to) => {
  const user = store.state.auth.user
  let redirect = false
  if (to.meta.requiresAuth) {
    if (store.getters.loggedIn) {
      const roles = to.meta.roles
      // console.log('user role', user?.role, roles)
      if (roles && Array.isArray(roles) && roles.length > 0) {
        if (!roles.includes(user?.role)) {
          redirect = true
        }
      }
    } else {
      redirect = true
    }
  }
  if (redirect) {
    return '/'
  }
})

app.use(i18n).use(store).use(router).use(createMetaManager()).mount('#app')
